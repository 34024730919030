import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useLangContext } from "../../domains/app/contexts/lang.context";
import { useRoomPreflight } from "../../domains/room/hooks/useRoomPreflight";
import { useRoomContext } from "../../domains/app/contexts/room.context";
import { RoomModel } from "../../domains/room/room.types";
import SafeAreaTopWrapper from "../../components/app/SafeAreaTopWrapper";
import { HeaderBoxSimple, HeaderLinks, NavHome, NavLang } from "../../components/app/headers/layout";
import { RoomHeader, RoomPageTab, RoomPageWrapper, RoomTabSwitcher } from "../../components/room/layout";
import Loader from "../../components/Loader";
import disableScroll from "disable-scroll";
import ForYouMainSlider from "../../components/forYou/ForYouMainSlider";
import { useLazyGetForYouContentQuery } from "../../domains/forYou/endpoints/getForYouContent";
import { AnalyticsItemList } from "../../tools/analytics/analytics.types";
import { gaEventViewItemListArticle } from "../../tools/analytics/articleAnalytics";
import { gaEventViewItemListNectar } from "../../tools/analytics/nectarAnalytics";
import { gaEventViewItemListVideo } from "../../tools/analytics/videoAnalytics";
import { displayToast } from "../../components/app/AppToast";
import { useForYouCacheRecommendedRoom } from "../../domains/forYou/hooks/useForYouCache";
import { useReadyState } from "../../domains/app/hooks/useReadyState";
import styled from "styled-components";

export default function RoomForYouPage() {
  const history = useHistory();
  const { roomId } = useParams<{ roomId: string }>();
  const { isInRoom, currentRoom } = useRoomContext();
  const { activeLang, t } = useLangContext();
  const { setAsReady, useReadyEffect } = useReadyState();
  const cache = useForYouCacheRecommendedRoom();
  const [isLoadingContent, setLoadingContent] = useState(!cache.list.length);
  const { isLoadingRoom, preflightRoom } = useRoomPreflight(roomId);
  const [getForYouContent, {
    isFetching: isFetchingContent,
  }] = useLazyGetForYouContentQuery();

  useEffect(() => {
    loadRoom();

    return () => {
      // NOTE: Clear cache to force refetch when back from other sections than For You
      if (!history.location.pathname.startsWith("/room/foryou")) {
        cache.update([], 0);
        localStorage.removeItem("foryou_carousel_index");
      }
    }
  }, []);

  useEffect(() => {
    if (isInRoom) {
      if (!cache.list.length) loadRecommendedContent();
      setAsReady();
    }
  }, [isInRoom]);

  useReadyEffect(() => loadRecommendedContent(true), [activeLang]);

  async function loadRoom() {
    try {
      disableScroll.off();
      await preflightRoom();
    } catch (error) {
      console.error("Couldn't load room.", error);
      history.push("/discovery");
    }
  }

  function loadRecommendedContent(resetList = false) {
    try {
      if (resetList) scrollTo(0, 0);
      setLoadingContent(true);

      getForYouContent({
        limit: 10,
        offset: resetList ? 0 : cache.list.length,
        language: activeLang,
      })
        .unwrap()
        .then((docs) => {
          const newList = resetList ? docs : [...cache.list, ...docs];
          cache.update(newList, newList.length);
          handleGTM(docs);
          setLoadingContent(false);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      console.error("Couldn't load recommended content.");
      displayToast(t("error:default"));
    }
  }

  function handleGTM(docs: any[]) {
    const articles = [];
    const videos = [];
    const nectars = [];

    for (const item of docs) {
      if ("apiVideo" in item) {
        videos.push(item);
      } else if ("answer" in item) {
        nectars.push(item);
      } else {
        articles.push(item);
      }
    }

    if (articles.length) {
      gaEventViewItemListArticle({
        articles,
        listName: AnalyticsItemList.FORYOU,
      });
    }

    if (nectars.length) {
      gaEventViewItemListNectar({
        nectars,
        listName: AnalyticsItemList.FORYOU,
      });
    }

    if (videos.length) {
      gaEventViewItemListVideo({
        videos,
        listName: AnalyticsItemList.FORYOU,
      });
    }
  }

  function handleSlideChange(newIndex: number) {
    if (newIndex >= cache.list.length - 3) {
      if (cache.list.length < 50 && !isFetchingContent) {
        loadRecommendedContent();
      }
    }
  }

  if (isLoadingRoom) return <Loader />;

  return (
    <Wrapper
      style={{
        backgroundColor: currentRoom?.primaryColor ?? "",
        gap: 0,
      }}
    >
      <SafeAreaTopWrapper>
        <HeaderBoxSimple>
          <HeaderLinks><NavHome /></HeaderLinks>
          <RoomHeader room={currentRoom as RoomModel} />
          <HeaderLinks><NavLang /></HeaderLinks>
        </HeaderBoxSimple>

        <RoomTabSwitcher activeTab={RoomPageTab.FORYOU} />
      </SafeAreaTopWrapper>

      <ForYouMainSlider
        contentList={cache.list}
        isLoading={isLoadingContent}
        // TODO: improve this part
        isLocked={isLoadingContent}
        onSlideChange={handleSlideChange}
      />
    </Wrapper>
  );
}

const Wrapper = styled(RoomPageWrapper)`
  .story-cover-container {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .fy-blender-container {
    padding-top: 48px;
  }
`;
